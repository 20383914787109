.carousel-item {
  // height: unset;
  .carousel-caption {
    .container {
      height: 70%;
      p {
        width: 50%;
      }
    }
  }
}

@media (max-width: 600px) {
  .carousel-item {
    // height: unset;
    .carousel-caption {
      position: relative;
      background-color: $primary;
      height: 250px;
      width: 100%;
      margin: 0;
      top: 0;
      left: 0;
      .container {
        p {
          width: 100%;
        }
      }
    }
  }
}

// .carousel-caption {
//     background: rgba(0, 0, 0, 0);
//     width: 100%;
//     height: 100%;
//     margin: 0;
//     left: 0;
//     bottom: 0;
//     padding-top: 15%;
//     .wrap-caption {
//         // background-color: aquamarine;
//         width: 50%;
//         // height: 50%;
//         // margin-top: 50%;
//         text-align: left;
//         margin-left: 10%;
//         h1 {
//             font-weight: 700;
//         }
//         p {
//             font-weight: 400;
//         }
//     }
// }

// .carousel-item {
// position: relative;
// display: flex;
// float: none;
// width: 100%;
// margin-right: -100%;
// backface-visibility: hidden;
// @include transition($carousel-transition);
//   }
