.megamenu {
    background-color: transparent;
}

.navbar {
    transition: 1s;
    background-color: rgba(255, 255, 255, 0.6);
    padding: 0;
    // background: linear-gradient(
    //     180deg,
    //     rgba(255, 255, 255, 0.8) 0%,
    //     rgba(255, 255, 255, 0.05) 100%
    // );
    &:hover {
        background-color: rgba(255, 255, 255);
    }
    .container {
        position: relative;
        .nav-link {
            font-weight: 500;
            color: #811010;
            // background-color: aqua;
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }
}

.megamenu-title a {
    color: black;
    text-decoration: none;
    font-weight: 600;
    padding: 3px;
    border-radius: 6px;
    &:hover {
        background-color: $secondary;
        color: white;
    }
}
.megamenu-desc {
    font-size: 10px;
    color: $light;
}


// .navbar { padding: 1rem; }
/* ============ desktop view ============ */
@media all and (min-width: 992px) {
	
	.navbar .has-megamenu{position:static!important;}
	.navbar .megamenu{left:0; right:0; width:100%; margin-top:2px; border: 0;  }

    .navbar .dropdown-menu-end{ right:0; left: auto;  }
	.navbar .nav-item .dropdown-menu{  display:block; opacity: 0;  visibility: hidden; transition:.3s; margin-top:2px; border: 0;  }
	// .navbar .nav-item:hover .nav-link{ color: #fff;  }
	.navbar .dropdown-menu.fade-down{ top:80%; transform: rotateX(-75deg); transform-origin: 0% 0%; }
	.navbar .dropdown-menu.fade-up{ top:180%;  }
	.navbar .nav-item:hover .dropdown-menu{ transition: .3s; opacity:1; visibility:visible; top:100%; transform: rotateX(0deg); }

	
}	
/* ============ desktop view .end// ============ */


/* ============ mobile view ============ */
@media(max-width: 991px){
.navbar {
    // padding-bottom: 15px;
    .container {
        padding-top: 10px;
        
}
}
.navbar .megamenu{left:0; right:0; width:100%; margin-top:0; border: 0;  }

	.navbar.fixed-top .navbar-collapse, .navbar.sticky-top .navbar-collapse{
		overflow-y: auto;
	    max-height: 90vh;
	    margin-top:10px;
	}
}
/* ============ mobile view .end// ============ */