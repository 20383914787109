.bg-gr-dark-0 {
  background-color: $dark;
  background: linear-gradient(0deg, $dark 0%, rgba(78, 0, 0, 0.05) 100%);
}

.bg-gr-primary {
  background: $primary;
  background: linear-gradient(
    90deg,
    rgba(222, 41, 41, 0.7) 0%,
    rgba(222, 41, 41, 0.042) 100%
  );
}
.bg-gr-secondary-vt {
  background: $secondary;
  background: linear-gradient(to top, $secondary 0%, $secondary 60%, #81101000 100%);
}

.bg-gr-secondary {
  background: $secondary;
  background: linear-gradient(90deg, $secondary 0%, #81101000 100%);
}

.bg-gr-danger {
  background: $danger;
  background: linear-gradient(90deg, $danger 0%, #81101000 100%);
}

.bg-gr-secondary-v {
  background: $secondary;
  background: linear-gradient(0deg, $secondary 0%, #81101000 100%);
}

.bg-gr-secondary-3 {
  background: $secondary;
  background: linear-gradient(90deg, #811010 20%, #811010c8 100%);
}

.bg-gr-ro {
  background: $primary;
  background: linear-gradient(0deg, rgb(255, 136, 0) 0%, rgb(180, 35, 35) 100%);
}

.bg-gr-ro-90 {
  background: $primary;
  background: linear-gradient(
    90deg,
    rgb(180, 35, 35) 0%,
    rgb(255, 136, 0) 100%
  );
}

.bg-tf-primary {
  background-color: rgba(223, 0, 0, 0.096);
}

.bg-tf-primary-dark {
  background-color: rgba(65, 1, 1, 0.699);
}

.bg-tf-dark {
  background-color: rgba(0, 0, 0, 0.6);
}

.bg-tf-dark-1 {
  background-color: rgba(0, 0, 0, 0.1);
}

.bg-tf-dark-25 {
  background-color: rgba(0, 0, 0, 0.25);
}

.bg-tf-dark-35 {
  background-color: rgba(0, 0, 0, 0.35);
}

.bg-footer-1 {
  background-color: rgba(255, 238, 238, 1);
}

.bg-footer-2 {
  background-color: rgba(158, 20, 20, 0.2);
}

.bg-wave {
  background-image: url("../img/bg-wave.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.bg-subnav-ttkami {
  background-color: rgb(235, 235, 235);
  color: #811010;
}
.bg-jo-b {
  background: #a12128;
  background: linear-gradient(
    0deg,
    #a12127 1%,
    #a1212700 40%,
    rgba(0, 0, 0, 1) 100%
  );
}
.bg-jo-t {
  background: #a12128;
  background: linear-gradient(
    180deg,
    #a12128 1%,
    #a1212700 40%,
    rgba(0, 0, 0, 1) 100%
  );
}

@media screen and (max-width: 500px){
  .bg-jo-t{
    background: rgba(0, 0, 0, 0.6);
  }
}

.img-gr-t {
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0)),
    to(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 1)),
    rgba(0, 0, 0, 1)
  );
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 1)
  );
}
