// Override default variables before the import
// $body-bg: #000;
$primary: #A12128;
$secondary: #9b0909;
$success: #070e51;
$info: #ebebeb;
// $warning: ;
$danger: #580f0f;
$light: #b8b5b5;
$dark: #544643;

$border-radius-lg: 0.6rem;

$box-shadow: 0.05rem 0.1rem 0.15rem 0.15rem rgba(black, 0.15);

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "./item.scss";
@import "./FloatNav.scss";
@import "./NavigationBar.scss";
@import "./footer.scss";
@import "./promo.scss";
@import "./TentangKami.scss";
@import "./carousel.scss";
@import "./JaringanLayanan.scss";
@import "./bg.scss";
@import "./sweeper.scss";
@import "./btn.scss";
@import "./CountSearch.scss";

* {
  font-family: "Poppins", sans-serif;
  // word-break: break-all;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16"><path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/></svg>');
  background-repeat: no-repeat;
  background-position-x: calc(100% - 1rem);
  background-position-y: 50%;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 2rem;
  padding: 1rem;
  padding-right: 2rem;
}

// select {
//     padding: 0 24px;
//  }

text {
  fill: #ffffff;
  font-size: 62px;
  letter-spacing: 10px;
}

.text-tf-primary-7 {
  color: #de2929aa;
}

.switch {
  position: relative;
  display: inline-block;
  width: 59px;
  height: 32px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $primary;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  font-size: 0.75rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  content: "ID";
  height: 32px;
  width: 32px;
  left: 0.5px;
  bottom: 0px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider:before {
  background-color: white;
  content: "EN";
}

input:checked + .slider {
  background-color: $success;
}

input:focus + .slider {
  box-shadow: 0 0 1px $success;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.bb-shadow {
  -webkit-box-shadow: 0px 4px 4px -1px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 4px 4px -1px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 4px 4px -1px rgba(0, 0, 0, 0.35);
}

.card-desc {
  // background-color: yellow;
  // font-size: 1.5rem;
  width: 100%;

  /**Major Properties**/
  overflow: hidden;
  line-height: 1rem;
  max-height: 8rem;
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}

.btn-kalfin {
  background: $light;
  &:hover {
    background: $light;
  }
  &.active {
    background: $dark;
    color: white;
  }
}


.card-icon-wraper:hover {
  scale: 1.05;
}

.card-icon-wraper {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 11%;
}

.logo-jw {
  width: 25%;
}

@media(max-width: 1023px){
  .card-icon-wraper {
    margin-left: 2px;
    margin-right: 2px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 24%;
  }
  .logo-jw {
    width: 50%;
  }
}

@media(max-width: 767px){
  .card-icon-wraper {
    margin-left: 2px;
    margin-right: 2px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 32%;
  }
  .logo-jw {
    width: 50%;
  }
  .OpenRekening {
    font-size: 12px;
    transform: translateY(4px) !important;
  }
}

@media (min-width : 747px){
  .OpenRekening {
    transform: translateY(8px) !important;
  }
}


h1, .h1{
  font-size: 26px;
}
.fs-4{
  font-size: 20px;
}

h4, .h4{
  font-size: 22px;
}

h5, .h5{
  font-size: 20px;
}

p{
  line-height: 25px !important;
}

figure.table{
  width: 100%;
  overflow-x: auto;
}

figure.table table{
  width: 100%;
}

figure.table table td{
  padding: 7px !important;
}

p{
  margin-bottom: 0;
}

body{
  overflow-x: hidden;
}

@media screen and (max-width: 500px) {
  .text-resp{
    font-size: 14px !important;
  }

  
}

.swiper-button-prev, .swiper-button-next{
  background-color: #fff;
  padding: 5px;
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
}
.swiper-button-prev::after, .swiper-button-next::after{
  font-size: 12px !important;
  font-weight: bold;
  color: #9b0909 !important;
}
.swiper-pagination-bullet{
  background-color: #ddd !important;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active{
  background-color: #9b0909 !important;
}