.float-nav {
    position: fixed;
    // z-index: 9999;
    background-color: $primary;
    width: 120px;
    right: -105px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    font-size: 11px;
    // right: 0;
    top: calc(50% - 100px);
    transition: 0.75s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:hover {
        right: 0;
        // background-color: aqua;
    }
    .menu-item {
        cursor: pointer;
        // background-color: aqua;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p.long {
            display: none;
        }
        &:hover {
            background-color: white;
            color: $secondary;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            height: 76px;
            width: 300px;
            padding-left: 20px;
            padding-top: 0;
            box-shadow: 0.05rem 0.1rem 0.15rem 0.15rem rgba(black, 0.15);;
            p.short {
                display: none;
                // margin-top: 12px;
                // padding-left: 10px;
                // font-size: 14px;
            }
            p.long {
                display: block;
                margin-top: 12px;
                padding-left: 10px;
                font-size: 14px;
            }
        }
    }
}
