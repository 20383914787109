.nav-tabs.promo {
    // background-color: aqua;
    .nav-link {
        background-color: white;
        font-weight: 700;
        -webkit-box-shadow: 0px -10px 15px -3px rgba(179, 179, 179, 0.3);
        -moz-box-shadow: 0px -10px 15px -3px rgba(179, 179, 179, 0.3);
        box-shadow: 0px -10px 15px -3px rgba(179, 179, 179, 0.3);
        &:hover {
            background-color: $primary;
            color: white;
        }
    }
    .nav-link.active {
        background-color: $primary;
        color: white;
    }
}

.nav-tabs.publikasi {
    // background-color: aqua;
    .nav-link {
        color: $light;
        margin-left: 5px;
        margin-right: 5px;
        padding: 5px;
        border-radius: 0;
        // background-color: white;
        // font-weight: 700;
        // -webkit-box-shadow: 0px -10px 15px -3px rgba(179, 179, 179, 0.3);
        // -moz-box-shadow: 0px -10px 15px -3px rgba(179, 179, 179, 0.3);
        // box-shadow: 0px -10px 15px -3px rgba(179, 179, 179, 0.3);
        &:hover {
            // background-color: $primary;
        // border: 1.5px rgb(48, 48, 48) solid;
        color: $dark;
        }
    }
    .nav-link.active {
        // background-color: $primary;
        color: $dark;
        border: 1.5px rgb(48, 48, 48) solid;
    }
}

.tidak-ada-promo {
    color: '#A12128';
}