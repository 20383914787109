.swiper {
    width: 100%;
    height: 100%;
}

// .swiper-container{overflow:visible}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}
.w-swiper {
    position: relative;
    margin: 10px;
    // margin: 5px;
    // width: 90%;
    .c-swiper {
        position: absolute;
        // width: calc(100% - 20px);
        // height: calc(100% - 20px);
        // top: 10px;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        color: white;
        text-align: center;
        // &:hover {
        //     width: 100%;
        //     height: 100%;
        //     top: 0px;
        // }
    }
    &:hover {
        margin: 0px;
        cursor: pointer;
    }
}

.swiper-slide .img-swiper-produk {
    border-radius: 0.6rem;
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.btn-outline-swiper {
    color: white;
    border: white solid 1px;
    font-size: 12px;

    &:hover {
        background-color: $primary;
        border: $primary solid 1px;
    }
}

.dw-swiper {
    position: relative;
    .c-swiper {
        position: absolute;
        // background: rgba(32, 0, 32, 0.808);
        // width: calc(100% - 20px);
        // height: calc(100% - 20px);
        // top: 10px;
        // width: 100%;
        // height: 100%;
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        // padding: 10px;
        color: white;
        text-align: left;
        padding: 3rem;
    }
}
.swiper-button-prev {
    margin-left: 50px;
}
.swiper-button-next {
    margin-right: 50px;
}