.card-jaringan-layanan {
    &:hover {
        background-color: $primary;
        .icon {
            color: white;
        }
        .title {
            color: white;
        }
    }
}