//  {
//     overflow: auto;
//     white-space: nowrap;
//     height: 330px;
// }

/* width */
::-webkit-scrollbar {
    height: 3px;
    width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f100;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(255, 0, 0, 0.25);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(255, 0, 0);
}

//  a {
//     width: 230px;
//     // background-color: #333;
//     height: 100%;
//     display: inline-block;
//     color: white;
//     text-align: center;
//     // padding: 14px;
//     text-decoration: none;
//     margin: 0 7px 0 7px;
// }

//  a:hover {
//     background-color: #777;
// }

//  a img {
//     display: block;
//     margin-left: auto;
//     margin-right: auto;
//     // width: auto;
//     overflow: hidden;
// }

.pointer {
    cursor: pointer;
}