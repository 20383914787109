.nav-tabs.tk {
  // background-color: aqua;
  .nav-link {
    background-color: rgb(235, 235, 235);
    color: $secondary;
    font-weight: 700;
    width: calc(50% - 5px);
    // margin-left: 5px;
    // margin-right: 5px;
    // -webkit-box-shadow: 0px -10px 15px -3px rgba(179, 179, 179, 0.3);
    // -moz-box-shadow: 0px -10px 15px -3px rgba(179, 179, 179, 0.3);
    // box-shadow: 0px -10px 15px -3px rgba(179, 179, 179, 0.3);

    &:hover {
      background-color: $secondary;
      color: white;
    }
  }

  .nav-link.active {
    background-color: $secondary;
    color: white;
  }
}

.nav-tabs.publikasi {
  // background-color: aqua;
  .nav-link {
    color: $light;
    margin-left: 5px;
    margin-right: 5px;
    padding: 5px;
    border-radius: 0;

    // background-color: white;
    // font-weight: 700;
    // -webkit-box-shadow: 0px -10px 15px -3px rgba(179, 179, 179, 0.3);
    // -moz-box-shadow: 0px -10px 15px -3px rgba(179, 179, 179, 0.3);
    // box-shadow: 0px -10px 15px -3px rgba(179, 179, 179, 0.3);
    &:hover {
      // background-color: $secondary;
      // border: 1.5px rgb(48, 48, 48) solid;
      color: $dark;
    }
  }

  .nav-link.active {
    // background-color: $secondary;
    color: $dark;
    border: 1.5px rgb(48, 48, 48) solid;
  }
}

.pub-img {
  width: 50%;
}

/* ============ mobile view ============ */
@media (max-width: 991px) {
  .pub-img {
    width: 100%;
  }
}
/* ============ mobile view .end// ============ */
