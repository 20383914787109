.footer {
    .f-1 {
    }
    .f-2 {
        .credit {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 30px;

            color: #211e1f;
        }
    }
}
